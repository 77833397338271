import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Host = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  perspective-origin: center center;
  perspective: 2000rem;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;

  .device {
    background: #FFFF;
    width: 300rem;
    aspect-ratio: 9/16;
    box-shadow: rgba(0, 0, 0, 0.1) 0 10rem 50rem;
    //border: 8rem solid #fff;
    border-radius: 20rem;
    overflow: hidden;

    ::before {
      content: "";
      height: 20rem;
      background: #f8fafc;
      border-radius: 0 0 10rem 10rem;
      width: 40%;
      left: 0;
      right: 0;
      position: absolute;
      margin: auto;
      z-index: 10;
    }
  }

`


export const GraphicOnCall = () => {
    const animationFrameRef = useRef<number>(0)
    const isMounted = useRef(true)
    const card1Ref = useRef<HTMLDivElement>(null)
    const valuesRef = useRef<Array<{ x: number; y: number; height: number }>>([
        { x: 5, y: 1, height: 0 },
        { x: 2, y: 15, height: -50 },
        { x: 8, y: 35, height: 200 },
    ])

    useEffect(() => {
        const paint = () => {
            if (!isMounted.current) {
                return
            }

            const new1X = Math.cos((valuesRef.current[0].x += 0.009)) * 6
            const new1Y = Math.cos((valuesRef.current[0].y += 0.003)) * 8
            const new1Height =
                Math.cos((valuesRef.current[0].height += 0.007)) * 12

            card1Ref.current.style.transform = `rotateX(${new1X}deg) rotateY(${new1Y}deg) translateY(${new1Height}rem)`

            animationFrameRef.current = requestAnimationFrame(paint)
        }

        if (animationFrameRef.current === 0) {
            animationFrameRef.current = requestAnimationFrame(paint)
        }

        return () => {
            isMounted.current = false
        }
    }, [])

    return <Host>
        <div className={"device"} ref={card1Ref}>
            <StaticImage
                src={"../../../../images/home/features/device-on-call.png"}
                alt={"The 23shout app making a call"}
                aspectRatio={9 / 16}
                quality={100}
            />
        </div>
    </Host>
}
