import React, { useEffect } from "react"
import styled from "styled-components"
import { animated, useTrail } from "@react-spring/web"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

const Host = styled.div`
    position: absolute;
    perspective: 2000rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    inset: 0;

    > div {
        display: flex;
        flex-direction: column;
        gap: 5rem;
        position: absolute;
        overflow: visible;
        justify-content: center;
        transform-origin: center;
        //transform: rotateY(10deg) rotateY(5deg);
        filter: drop-shadow(0 48rem 100rem rgba(17, 12, 46, 0.15));

        > .number {
            display: flex;
            flex-direction: column;
            gap: 10rem;
            background: #fff;
            padding: 17rem;
            position: relative;
            color: ${({ theme }) => theme.text.light};
            border-radius: 10rem;
            //box-shadow: rgba(17, 12, 46, 0.15) 0 48rem 100rem 0;
            width: 270rem;
            text-rendering: optimizeLegibility;
            backface-visibility: hidden;

            &.primary {
                box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
                z-index: 20;
                margin: auto;
                left: 0;
                right: 0;
            }

            > div {
                display: flex;
                align-items: center;
                gap: 10rem;

                .name {
                    color: ${({ theme }) => theme.text.normal};
                    font-family: "Gilroy-Medium", sans-serif;
                }

                .status {
                    padding: 5rem 10rem;
                    border-radius: 8rem;
                    font-size: 12rem;
                    font-weight: 600;
                    transition: all ease-in-out 250ms;
                    text-align: center;

                    &.active {
                        background: #cbf4c9;
                        color: #0e6245;
                    }

                    &.spam {
                        background: #fecdd3;
                        color: #be123c;
                    }

                    &.ooh {
                        background: #bfdbfe;
                        color: #1d4ed8;
                    }

                    &.forwarding {
                        background: #fef9c3;
                        color: #a16207;
                    }
                }
            }
        }
    }
`

const NUMBERS: Array<{
    number: string
    status: string
    statusText: string
    flagImage: JSX.Element
    name: string
}> = [
    {
        number: "1800 951 343",
        status: "active",
        statusText: "Active",
        flagImage: (
            <StaticImage
                src={`../../images/features/phone-numbers/aus.png`}
                alt={"Australian flag"}
                layout={"fixed"}
                height={30}
                placeholder={"none"}
            />
        ),
        name: "Aus support",
    },
    {
        number: "(202) 324-3000",
        status: "active",
        statusText: "Active",
        flagImage: (
            <StaticImage
                src={`../../images/features/phone-numbers/usa.png`}
                alt={"American flag"}
                layout={"fixed"}
                height={30}
                placeholder={"none"}
            />
        ),
        name: "USA support",
    },
    {
        number: "0491 576 398",
        status: "ooh",
        statusText: "Out of hours",
        flagImage: (
            <StaticImage
                src={`../../images/features/phone-numbers/aus.png`}
                alt={"Australian flag"}
                layout={"fixed"}
                height={30}
                placeholder={"none"}
            />
        ),
        name: "Prospecting",
    },
    {
        number: "0491 578 888",
        status: "spam",
        statusText: "Marked as spam",
        flagImage: (
            <StaticImage
                src={`../../images/features/phone-numbers/aus.png`}
                alt={"Australian flag"}
                layout={"fixed"}
                height={30}
                placeholder={"none"}
            />
        ),
        name: "Customer Success",
    },
]

export const GraphicSpamDetection = () => {
    const [trail, api] = useTrail(4, () => ({
        rotateX: "-180deg",
        config: { mass: 1, tension: 180, friction: 12 },
    }))

    useEffect(() => {
        api.start((i) => ({
            rotateX: "0deg",
            transformZ: `${i * 50}rem`,
            config: { mass: 1, tension: 150, friction: 5 },
        }))
    }, [])

    return (
        <Host>
            <div>
                {trail.map((t, i) => {
                    const number = NUMBERS[i]

                    return (
                        <animated.div
                            className={"number"}
                            style={{ ...t, zIndex: i }}
                        >
                            <div className={"primary active"}>
                                {number.flagImage}
                                <div className={"name"}>{number.name}</div>
                            </div>
                            <div>
                                <div
                                    className={classNames(
                                        "status",
                                        number.status
                                    )}
                                >
                                    {number.statusText}
                                </div>
                                <div className={"number"}>{number.number}</div>
                            </div>
                        </animated.div>
                    )
                })}
            </div>
        </Host>
    )
}
