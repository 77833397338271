import React, { useEffect, useState } from "react"
import { Flex } from "../../../shared/Flex/Flex"
import { FeaturesGraphic } from "./FeaturesGraphic"
import { Constrained } from "../../../shared/Constrained/Constrained"
import { Feature } from "./types"
import { FeaturesList } from "./FeaturesList"
import { GraphicOnCall } from "./Graphics/GraphicOnCall"
import { GraphicMessages } from "./Graphics/GraphicMessages"
import { GraphicScript } from "./Graphics/GraphicScripts"
import { GraphicSpamDetection } from "./Graphics/GraphicSpamDetection"
import { GraphicWorkflows } from "./Graphics/GraphicWorkflows"
import { GraphicAnalytics } from "./Graphics/GrapicAnalytics"
import styled from "styled-components"
import media from "css-in-js-media"

const FeatureList: Array<Feature> = [
    {
        title: "Calling",
        description:
            "Call to and from local, mobile, and toll free numbers globally. Customise how inbound calls are routed to your team.",
        graphic: <GraphicOnCall />,
        icon: (
            <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                z
                <path
                    d="M5 4.849H9L11 9.849L8.5 11.349C9.57096 13.5205 11.3285 15.278 13.5 16.349L15 13.849L20 15.849V19.849C20 20.9536 19.1046 21.849 18 21.849C9.92765 21.3584 3.49056 14.9213 3 6.849C3 5.74443 3.89543 4.849 5 4.849"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
    {
        title: "Call scripts",
        description:
            "Show dynamic scripts for calls with their name, location, and more. If you edit a property we’ll sync it back to your CRM.",
        graphic: <GraphicScript />,
        icon: (
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.5 17V5C20.5 3.879 19.621 3 18.5 3C17.379 3 16.5 3.879 16.5 5V17L18.5 19L20.5 17Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.5 7H20.5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.5 19H5.5C4.39543 19 3.5 18.1046 3.5 17C3.5 15.8954 4.39543 15 5.5 15H9.5C10.6046 15 11.5 14.1046 11.5 13C11.5 11.8954 10.6046 11 9.5 11H6.5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
    {
        title: "Messaging",
        description:
            "Send and receive SMS messages from a team inbox, search through them with natural language machine learning.",
        graphic: <GraphicMessages />,
        icon: (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4 21V8C4 6.34315 5.34315 5 7 5H17C18.6569 5 20 6.34315 20 8V14C20 15.6569 18.6569 17 17 17H8L4 21"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 9H16"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 13H14"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
    {
        title: "Spam detection",
        description:
            "We’re the only business phone that will tell you when your numbers have been marked as spam. We’ll even automatically rotate them out for you!",
        graphic: <GraphicSpamDetection />,
        icon: (
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.5 7H17.5C18.0304 7 18.5391 7.21071 18.9142 7.58579C19.2893 7.96086 19.5 8.46957 19.5 9V10L20.5 11V14L19.5 15V18C19.5 18.5304 19.2893 19.0391 18.9142 19.4142C18.5391 19.7893 18.0304 20 17.5 20H7.5C6.96957 20 6.46086 19.7893 6.08579 19.4142C5.71071 19.0391 5.5 18.5304 5.5 18V15L4.5 14V11L5.5 10V9C5.5 8.46957 5.71071 7.96086 6.08579 7.58579C6.46086 7.21071 6.96957 7 7.5 7V7Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5 16H14.5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9 12C9.27614 12 9.5 11.7761 9.5 11.5C9.5 11.2239 9.27614 11 9 11C8.72386 11 8.5 11.2239 8.5 11.5C8.5 11.7761 8.72386 12 9 12Z"
                    fill="#475569"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16 12C16.2761 12 16.5 11.7761 16.5 11.5C16.5 11.2239 16.2761 11 16 11C15.7239 11 15.5 11.2239 15.5 11.5C15.5 11.7761 15.7239 12 16 12Z"
                    fill="#475569"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.5 7L8.5 3"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5 7L16.5 3"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
    {
        title: "Automations",
        description:
            "Link in to your existing tools with over 5,000 extensions. Define workflows to run after calls end.",
        graphic: <GraphicWorkflows />,
        icon: (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 8L3 12L7 16"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17 8L21 12L17 16"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14 4L10 20"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
    {
        title: "Analytics",
        description:
            "View call rates, durations, and outcomes. Compare performance amongst agents and deep dive into your flow.",
        graphic: <GraphicAnalytics />,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="3" y="4" width="18" height="12" rx="1" />
                <path d="M7 20h10" />
                <path d="M9 16v4" />
                <path d="M15 16v4" />
                <path d="M9 12v-4" />
                <path d="M12 12v-1" />
                <path d="M15 12v-2" />
                <path d="M12 12v-1" />
            </svg>
        ),
    },
]

const Host = styled(Flex)`
    padding: 100rem 0;

    ${media("<desktop")} {
        padding: 0;
        flex-flow: column nowrap !important;
    }
`

export const Features = () => {
    const [selected, setSelected] = useState<Feature>(FeatureList[0])
    const [seed, setSeed] = useState(0)

    useEffect(() => setSeed(Math.random() * 100), [selected])

    return (
        <Constrained>
            <Host
                alignItems={"center"}
                justifyContent={"flex-end"}
                className={"flex"}
            >
                <FeaturesGraphic seed={seed} graphic={selected.graphic} />
                <FeaturesList
                    features={FeatureList}
                    onChange={(newFeature) => setSelected(newFeature)}
                    selectedFeature={selected.title}
                />
            </Host>
        </Constrained>
    )
}
