import React from "react"
import styled from "styled-components"
import { Flex } from "../../../shared/Flex/Flex"
import { TextButton } from "../../../shared/Ui/TextButton/TextButton"
import { Link } from "gatsby"
import media from "css-in-js-media"

const TestimonialBlock = styled.div`
    display: flex;
    flex: 1;

    > div {
        flex: 0;

        &:first-of-type {
            aspect-ratio: 16/9;
            height: 220rem;
            position: relative;

            .logo {
                position: absolute;
                inset: 0;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &:last-of-type {
            flex: 1;
            padding: 10rem 30rem;

            .body {
                color: #6b7280;
                font-family: Inter, sans-serif;
                font-weight: 400;
                line-height: 154.4%;
                font-size: 16rem;
                margin-bottom: 10rem;
            }
        }
    }
`

const Host = styled(Flex)`
    overflow: hidden;
    border-radius: 0 0 10rem 10rem;

    ${media("<desktop")} {
        flex-direction: column !important;

        > div:nth-of-type(even) {
            flex-direction: row-reverse;
        }

        .image-wide {
            display: none;
        }
    }

    ${media("<tablet")} {
        > div {
            flex-direction: column !important;

            .image-block {
                aspect-ratio: unset !important;
                height: unset !important;

                .image-scaled {
                    display: none;
                }

                .image-wide {
                    display: block;
                }
            }

            > div:last-of-type {
                padding: 30rem 40rem;
                text-align: center;
                align-items: center !important;
            }
        }
    }

    ${media("<largeDesktop", ">desktop")} {
        > div:not(.ms) {
            .image-block {
                aspect-ratio: unset !important;
            }

            .image-wide {
                display: block;
                height: 140rem;
            }

            .image-scaled {
                display: none;
            }

            flex-direction: column;

            > div:last-of-type {
                padding: 30rem 40rem;
                text-align: center;
                align-items: center !important;
            }
        }
    }
`

interface TestimonialsLowerProps {
    testimonials: Array<{
        photoScaled: JSX.Element
        photoWide: JSX.Element
        logo: JSX.Element
        companyName: string
        companyText: string
        linkTo: string
    }>
}

export const TestimonialsLower = ({ testimonials }: TestimonialsLowerProps) => {
    return (
        <Host>
            {testimonials.map((t) => {
                return (
                    <TestimonialBlock key={t.companyName}>
                        <div className={"image-block"}>
                            {t.photoScaled}
                            {t.photoWide}
                            <div className={"logo"}>{t.logo}</div>
                        </div>
                        <Flex direction={"column"} justifyContent={"center"}>
                            <div className={"body"}>{t.companyText}</div>
                            <Link to={`${t.linkTo}`}>
                                <TextButton color={"primary"}>
                                    {t.companyName}
                                    {t.companyName[
                                        t.companyName.length - 1
                                    ].toLowerCase() == "s"
                                        ? `'`
                                        : `'s`}{" "}
                                    story
                                </TextButton>
                            </Link>
                        </Flex>
                    </TestimonialBlock>
                )
            })}
        </Host>
    )
}
