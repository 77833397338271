import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { HeaderTop } from "../shared/Header/HeaderTop"
import { HeaderBottom } from "../shared/Header/HeaderBottom"
import { Page } from "../shared/Page/Page"
import { Flex } from "../shared/Flex/Flex"
import { Hero } from "../components/Home/Hero"
import { Footer } from "../shared/Footer/Footer"
import { Constrained } from "../shared/Constrained/Constrained"
import { Features } from "../components/Home/Features/Features"
import { Testimonials } from "../components/Home/Testimonials/Testimonials"
import classNames from "classnames"
import { HeaderMobile } from "../shared/Header/HeaderMobile"
import { Extensions } from "../components/Home/Extensions/Extensions"

const Container = styled.div`
    padding: 0 20rem;

    .ShoutNextHeroStack-root {
        background: linear-gradient(45deg, #f8fafc, #f8fafc);
        margin: 0 -20rem;
        padding: 0 20rem;

        .ShoutBannerTop-root {
            border-bottom: none;
        }

        .header-wrapper {
            height: 80rem;

            .header-container {
                &.isHeaderSticky {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    background: #fff;
                    z-index: 1000;
                    box-shadow: rgba(0, 0, 0, 0.04) 0 3px 5px;
                    padding: 0 20rem;
                }

                .ShoutHeaderBottom-root {
                    .ShoutLogo-root {
                        display: none;
                    }
                }
            }
        }
    }
`

const Index = ({ location }) => {
    const headerWrapperRef = useRef<HTMLDivElement>(null)

    const [isHeaderSticky, setHeaderSticky] = useState(false)

    // on render, set listener
    useEffect(() => {
        window.addEventListener("scroll", checkHeaderSticky)

        return () => {
            window.removeEventListener("scroll", checkHeaderSticky)
        }
    }, [])

    const checkHeaderSticky = () => {
        const scrollTop = window.scrollY
        setHeaderSticky(scrollTop >= headerWrapperRef.current!.offsetTop)
    }

    return (
        <Page disableHeader disableCta disableFooter disableGlobalPadding>
            <Container>
                <Flex
                    as={"section"}
                    direction={"column"}
                    className={"ShoutNextHeroStack-root"}
                    alignItems={"stretch"}
                >
                    <HeaderTop />
                    <HeaderMobile />
                    <Hero />
                    <div className={"header-wrapper"} ref={headerWrapperRef}>
                        <div
                            className={classNames("header-container", {
                                isHeaderSticky,
                            })}
                        >
                            <Constrained>
                                <HeaderBottom />
                            </Constrained>
                        </div>
                    </div>
                </Flex>
                <Flex direction={"column"}>
                    <Features />
                    <Testimonials />
                </Flex>
                <Extensions />
            </Container>
            <Footer constrained />
        </Page>
    )
}

export default Index
