import React, { useEffect, useRef, useState } from "react"
import styled, { keyframes } from "styled-components"

const LINE_COUNT = 100
const X_MAX = 300
const Y_MAX = 247
const FREQUENCY = 4.6
const AMPLITUDE = 20
const JITTER = 20

const PulseAnimation = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10rem rgba(59, 130, 246, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
`

const CalloutAppear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Host = styled.div`
    position: absolute;
    perspective: 2000rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    inset: 0;

    > div {
        display: flex;
        position: absolute;
        overflow: visible;
        justify-content: center;
        transform-origin: center;
    }

    .graphs {
        width: 600rem;
        height: 600rem;
        position: relative;

        .back {
            position: absolute;
            border-radius: 20rem;
            background: linear-gradient(135deg, #eff6ff, #ecfdf5);
            inset: 50rem;
            transform: translate(30rem, 30rem);
            z-index: 10;
        }

        .head {
            display: flex;
            flex-direction: column;
            margin-bottom: 20rem;
            gap: 5rem;

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .title {
                font-family: "Gilroy-SemiBold", sans-serif;
                color: ${({ theme }) => theme.text.dark};
                font-size: 16rem;
            }

            .total {
                font-family: "Gilroy-SemiBold", sans-serif;
                font-size: 16rem;
                color: #1e3a8a;
            }

            .time-range {
                display: flex;
                align-items: center;
                gap: 5rem;
                font-size: 12rem;
                color: ${({ theme }) => theme.text.light};

                svg {
                    width: 20rem;
                    height: 20rem;
                }
            }

            .adjustment {
                color: #3b82f6;
                font-size: 12rem;
            }
        }

        .graph {
            position: absolute;
            width: 400rem;
            height: 400rem;
            background: #fff;
            display: flex;
            flex-direction: column;
            box-shadow: #e0f2fe 0 8rem 24rem;
            z-index: 20;
            border-radius: 15rem;
            padding: 30rem 35rem;
            text-rendering: optimizeLegibility;

            &.outcomes {
                box-shadow: #ecfdf5 0 8rem 24rem,
                    rgba(100, 100, 111, 0.2) 0rem 7rem 29rem 0;
                bottom: 50rem;
                right: 50rem;
                height: 250rem;

                .grid {
                    background-color: #ffffff;
                    opacity: 1;
                    background-size: 30px 30px;
                    background-image: repeating-linear-gradient(
                        0deg,
                        #f1f5f9,
                        #f1f5f9 1.5px,
                        #ffffff 1.5px,
                        #ffffff
                    );
                }

                .total {
                    color: #064e3b;
                }

                .adjustment {
                    color: #10b981;
                }
            }

            .grid {
                flex: 1;
                background-color: #ffffff;
                opacity: 1;
                background-image: radial-gradient(
                    #cbd5e1 0.75px,
                    #ffffff 0.75px
                );
                background-size: 15px 15px;
                position: relative;

                svg {
                    position: absolute;
                    bottom: 0;
                }

                .callout {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    gap: 10rem;
                    top: 80rem;
                    left: 76rem;
                    z-index: 20;
                    opacity: 0;

                    .text {
                        background: #3b82f6;
                        padding: 5rem 10rem;
                        color: #fff;
                        border-radius: 15rem 5rem 5rem 15rem;
                        font-size: 12rem;
                    }

                    .point {
                        display: block;
                        width: 10rem;
                        height: 10rem;
                        border-radius: 10rem;
                        background: #3b82f6;

                        animation: ${PulseAnimation} 2s infinite;
                    }

                    animation: ${CalloutAppear} 1s 1;
                    animation-delay: 2s;
                    animation-fill-mode: forwards;
                }
            }

            .columns {
            }
        }
    }
`

export const GraphicAnalytics = () => {
    const animationFrameRef = useRef<number>(0)
    const isMounted = useRef(true)
    const card1Ref = useRef<HTMLDivElement>(null)
    const card2Ref = useRef<HTMLDivElement>(null)
    const valuesRef = useRef<Array<{ x: number; y: number; height: number }>>([
        { x: 5, y: 1, height: 0 },
        { x: 2, y: 15, height: -50 },
        { x: 8, y: 35, height: 200 },
    ])

    const [chartAreaD, setChartAreaD] = useState("")
    const [chartLineD, setChartLineD] = useState("")

    useEffect(() => {
        const paint = () => {
            if (!isMounted.current) {
                return
            }

            const new1X = Math.cos((valuesRef.current[0].x += 0.009)) * 1
            const new1Y = Math.cos((valuesRef.current[0].y += 0.003)) * 2
            const new1Height =
                Math.cos((valuesRef.current[0].height += 0.007)) * 3

            const new3X = Math.cos((valuesRef.current[2].x += 0.008)) * 1
            const new3Y = Math.cos((valuesRef.current[1].y += 0.008)) * 3
            const new3Height =
                Math.cos((valuesRef.current[2].height += 0.03)) * 0.05

            card1Ref.current.style.transform = `scale(0.7) rotateX(${new1X}deg) rotateY(${new1Y}deg) translateY(${new1Height}rem) translateZ(-80rem)`
            card2Ref.current.style.transform = `rotateX(${new3X}deg) rotateY(${new3Y}deg) translateY(${new3Height}rem) translateZ(-10rem)`

            animationFrameRef.current = requestAnimationFrame(paint)
        }

        // Step sizes
        const xStepSize = X_MAX / LINE_COUNT
        const yStepSize = Y_MAX / LINE_COUNT

        // Our return path
        let newD = ``

        // Populate
        for (let i = 0; i < LINE_COUNT; i++) {
            const yPoint = Math.min(
                Y_MAX,
                Math.cos(i / FREQUENCY) * AMPLITUDE +
                    i * yStepSize +
                    Math.random() * JITTER
            )

            if (i === 0) {
                newD += `M ${xStepSize * i},${Y_MAX - yPoint}`
            }

            newD += ` L ${xStepSize * i},${Y_MAX - yPoint}`
        }

        setChartLineD(newD)

        newD += `L${X_MAX},${Y_MAX} L0,${Y_MAX}`

        setChartAreaD(newD)

        if (animationFrameRef.current === 0) {
            animationFrameRef.current = requestAnimationFrame(paint)
        }

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <Host>
            <div className={"graphs"}>
                <div className={"graph"} ref={card1Ref}>
                    <div className={"head"}>
                        <div>
                            <div className={"title"}>Average talk time</div>
                            <div className={"total"}>16 hours today</div>
                        </div>
                        <div>
                            <div className={"time-range"}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V9h14v10zM7 11h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2z" />
                                </svg>
                                Today
                            </div>
                            <div className={"adjustment"}>+ 3 hours</div>
                        </div>
                    </div>
                    <div className={"grid"}>
                        <div className={"callout"}>
                            <div className={"text"}>Added Joy to the team</div>
                            <div className={"point"} />
                        </div>
                        <svg
                            viewBox={`0 0 ${X_MAX} ${Y_MAX}`}
                            stroke={"transparent"}
                        >
                            <defs>
                                <linearGradient
                                    id={"analyticsChart1FillGradient"}
                                    x1={"50%"}
                                    y1={"0%"}
                                    x2={"50%"}
                                    y2={"100%"}
                                >
                                    <stop offset={"0%"} stopColor={"#E0F2FE"} />
                                    <stop offset={"100%"} stopColor={"#FFF"} />
                                </linearGradient>
                            </defs>
                            <path
                                d={chartAreaD}
                                fill={"url(#analyticsChart1FillGradient)"}
                            />
                            <path
                                d={chartLineD}
                                stroke={"#7DD3FC"}
                                fill={"transparent"}
                            />
                        </svg>
                    </div>
                </div>

                <div className={"graph outcomes"} ref={card2Ref}>
                    <div className={"head"}>
                        <div>
                            <div className={"title"}>Lead count</div>
                            <div className={"total"}>38 this week</div>
                        </div>
                        <div>
                            <div className={"time-range"}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V9h14v10zM7 11h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2z" />
                                </svg>
                                This week
                            </div>
                            <div className={"adjustment"}>+ 4 leads</div>
                        </div>
                    </div>
                    <div className={"grid"}>
                        <svg
                            viewBox={`0 0 ${X_MAX} 100`}
                            stroke={"transparent"}
                        >
                            <defs>
                                <linearGradient
                                    id={"analyticsChart1FillGradient"}
                                    x1={"50%"}
                                    y1={"0%"}
                                    x2={"50%"}
                                    y2={"100%"}
                                >
                                    <stop offset={"0%"} stopColor={"#E0F2FE"} />
                                    <stop offset={"100%"} stopColor={"#FFF"} />
                                </linearGradient>
                            </defs>
                            {Array(7)
                                .fill(null)
                                .map((v, i) => {
                                    const height = Math.max(
                                        20,
                                        Math.random() * 80
                                    )
                                    return (
                                        <rect
                                            fill={"#A7F3D0"}
                                            x={i * 45}
                                            y={95 - height}
                                            width={20}
                                            height={height}
                                            radius={10}
                                        />
                                    )
                                })}
                        </svg>
                    </div>
                </div>
            </div>
        </Host>
    )
}
