import React, { useEffect } from "react"
import styled from "styled-components"
import { useTrail } from "@react-spring/core"
import { animated } from "@react-spring/web"
import classNames from "classnames"

const Host = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  inset: 0;

  > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 10rem;
    max-width: 300rem;
    perspective: 2000px;


    .message-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15rem;

      padding-right: 50rem;
      transform: rotateY(10deg);

      &.inbound {
        justify-content: flex-end;
        padding-right: 0;
        padding-left: 50rem;
        flex-direction: row-reverse;
        transform: rotateY(-10deg);

        .body {
          background-color: #6366f1;
          border-radius: 10rem 5rem 5rem 10rem;
          color: #eef2ff;
          margin-left: auto;
          margin-right: unset;
        }
        
        .avatar {
          background: #4f46e5;
        }
      }

      .avatar {
        min-width: 10rem;
        min-height: 10rem;
        border-radius: 90%;
        background: #2563eb;
        display: none;
      }

      .body {
        padding: 10rem 15rem;
        background: ${({ theme }) => theme.primary};
        border-radius: 5rem 10rem 10rem 5rem;
        color: #eff6ff;
        font-size: 16rem;
        line-height: 24rem;
        font-family: "Inter", sans-serif;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        margin-right: auto;
      }
    }
  }
`

const messages: Array<{ inbound: boolean, body: string }> = [
    {
        inbound: true,
        body: "Hey, I heard 23shout can do SMS too?",
    },
    {
        inbound: false,
        body: "That's right 😎",
    },
    {
        inbound: false,
        body: "It works on the web and on mobile",
    },
    {
        inbound: true,
        body: "That's pretty cool. What's the cost?",
    },
    {
        inbound: false,
        body: "Haha, SMS is bundled free!",
    },
    {
        inbound: true,
        body: "🤯",
    },

]

export const GraphicMessages = () => {
    const [trail, api] = useTrail(6, () => ({ scale: 0 }))

    useEffect(() => {
        api.start({
            scale: 1,
            config: { mass: 1, tension: 210, friction: 20 },
        })
    }, [])

    return <Host>
        <div>
            {trail.map((d, i) => {
                const message = messages[i]

                return <div className={classNames("message-row", { inbound: message.inbound })} key={`message-${i}`}>
                    <div className={"avatar"} />
                    <animated.div style={d} className={"body"}>
                        {message.body}
                    </animated.div>
                </div>
            })}
        </div>
    </Host>
}
