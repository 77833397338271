import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { transparentize } from "polished"


const Host = styled.div`
  position: absolute;
  perspective: 2000rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  inset: 0;
  

  > div {
    display: flex;
    position: absolute;
    overflow: visible;
    justify-content: center;
    transform-origin: center;
  }

  .script {
    position: relative;
    height: 510rem;
    width: 400rem;
    overflow: visible;
    perspective: 1500rem;
    perspective-origin: center;
    transform-origin: center center;
    user-select: none;

    .script-name {
      display: flex;
      align-items: center;
      gap: 10rem;
      font-size: 12rem;
      color: ${({ theme }) => theme.text.lighter};
      font-family: "Gilroy-Regular", sans-serif;
      position: absolute;
      z-index: 50;
      top: -15rem;
      left: 45rem;

      svg {
        width: 15rem;
        height: 15rem;
      }
    }

    .paper {
      position: absolute;
      inset: 0 0 0 0;
      margin: 10rem 10rem;
      background: #fff;
      border-radius: 15px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }

    .text {
      z-index: 50;
      position: absolute;
      inset: 0 0 0 0;
      margin: 35rem 37rem;

      > div {
        > div {
          margin-bottom: 10rem;
        }

        > div.line {
          font-size: 13rem;
          line-height: 28rem;
          color: ${({ theme }) => theme.text.light};

          span {
            padding: 5rem 10rem;
            background: ${({ theme }) => transparentize(0.9, theme.primary)};
            color: ${({ theme }) => theme.primary};
            margin: 0 2rem;
            border-radius: 5rem;
          }
        }

        > div.deferral {
          display: inline-flex;
          align-items: center;
          font-size: 12rem;
          padding: 5rem 15rem;
          border-radius: 5rem;
          font-weight: 600;
          color: ${({ theme }) => theme.primary};
          border: 1px solid ${({ theme }) => theme.borders.light};
        }

        > div.red-objection {
          margin-bottom: 20rem;

          > span {
            background: #fff1f2;
            color: #e11d48;
            border-radius: 10rem 10rem 0 0;
            padding: 5rem 10rem;
            margin-left: 15rem;
            font-size: 10rem;
            font-weight: 600;
            display: inline-block;
          }

          > div {
            background: #fff1f2;
            color: #e11d48;
            border-radius: 10rem;
            padding: 10rem 15rem;
            font-size: 12rem;
            line-height: 28rem;
          }

          .deferral {
            display: inline-flex;
            align-items: center;
            padding: 0rem 10rem;
            font-size: 12rem;
            border-radius: 5rem;
            font-weight: 500;
            margin-top: 5rem;
            color: #e11d48;
            border: 1px solid #fecdd3;

            svg {
              height: 15rem;
            }
          }
        }

        > div.buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20rem;

          > div.deferral {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12rem;
            padding: 10rem 15rem;
            border-radius: 5rem;
            font-weight: 600;
            color: ${({ theme }) => theme.primary};
            border: 1px solid ${({ theme }) => theme.borders.light};
          }
        }
      }
    }
  }

`

export const GraphicScript = () => {
    const isMounted = useRef(true)
    const animationFrameRef = useRef<number>()
    const animContainerRef = useRef<HTMLDivElement>(null)
    const valuesRef = useRef<Array<{ x: number; y: number; height: number }>>([
        { x: 5, y: 1, height: 0 },
        { x: 2, y: 15, height: -50 },
        { x: 8, y: 35, height: 200 },
    ])

    useEffect(() => {
        const paint = () => {
            if (!isMounted.current) {
                return
            }
            const new1X = Math.cos((valuesRef.current[0].x += 0.009)) * 6
            const new1Y = Math.cos((valuesRef.current[0].y += 0.003)) * 8
            const new1Height =
                Math.cos((valuesRef.current[0].height += 0.007)) * 12

            animContainerRef.current.style.transform = `rotateX(${new1X}deg) rotateY(${new1Y}deg) translateY(${new1Height}rem)`

            animationFrameRef.current = requestAnimationFrame(paint)
        }

        if (animationFrameRef.current !== 0) {
            animationFrameRef.current = requestAnimationFrame(paint)
        }

        return () => {
            isMounted.current = false
        }
    }, [])

    return <Host
        className={"anim-container"}
    >
        <div ref={animContainerRef}
        >
            <div className={"script"}>
                <div className={"script-name"}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="currentColor"
                    >
                        <path
                            d="M3,6C2.45,6,2,5.55,2,5V2c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1S6.55,3,6,3H4v2C4,5.55,3.55,6,3,6z M17,2 c0,0.55,0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1h-3C17.45,1,17,1.45,17,2z M3,18c-0.55,0-1,0.45-1,1v3 c0,0.55,0.45,1,1,1h3c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1H4v-2C4,18.45,3.55,18,3,18z M17,22c0,0.55,0.45,1,1,1h3 c0.55,0,1-0.45,1-1v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2C17.45,21,17,21.45,17,22z M19,18c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V6 c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2V18z M9,9c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,8,9,8.45,9,9z M9,12c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,11,9,11.45,9,12z M9,15c0,0.55,0.45,1,1,1h4 c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,14,9,14.45,9,15z" />
                    </svg>
                    Prospecting script
                </div>
                <div className={"text"}>
                    <div>
                        <div className={"line"}>
                            Hi <span>Janet</span>, this is{" "}
                            <span>Marcus</span> from 7C Group,
                            is now a good time to chat?
                        </div>
                        <div className={"deferral"}>
                            Can't talk now <Chevron />
                        </div>
                        <div className={"line"}>
                            Right now 7C is working on a new
                            solution to problems in{" "}
                            <span>Brisbane, Australia</span>, is
                            that something you'd want more
                            information on?
                        </div>
                        <div className={"red-objection"}>
                            <span>If not interested</span>
                            <div>
                                Do you know anyone else in the
                                industry who would be looking to
                                generate more sales instead?
                                <br />
                                <div className={"deferral"}>
                                    Offers alternate contact{" "}
                                    <Chevron />
                                </div>
                            </div>
                        </div>
                        <div className={"line"}>
                            Which one of those solutions
                            interests you the most?
                        </div>
                        <div className={"buttons"}>
                            <div className={"deferral"}>
                                Seasonal reports
                            </div>
                            <div className={"deferral"}>
                                Buyer intent data
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"paper"}></div>
            </div>
        </div>
    </Host>
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path
                d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}
