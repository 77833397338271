import React, { PropsWithChildren } from "react"
import styled from "styled-components"
import classNames from "classnames"

const Host = styled.div`
    display: flex;
    margin-left: -20rem;
    min-width: 100vw;
`

interface DepadProps {
    className?: string
}

/**
 * An aggressive component to fill the page and ignore containing padding;
 */
export const Depad = ({
    children,
    className = "",
}: PropsWithChildren<DepadProps>) => {
    return (
        <Host className={classNames("ShoutDepad-root", className)}>
            {children}
        </Host>
    )
}
