import React from "react"
import { Feature } from "./types"
import styled from "styled-components"
import classNames from "classnames"
import { Flex } from "../../../shared/Flex/Flex"
import media from "css-in-js-media"
import { coolGrey } from "../../../utils/colors"

const FeaturesListContainer = styled.div`
    flex: 1 20%;
    display: flex;
    gap: 30rem;
    flex-wrap: wrap;
    padding: 30rem 0;
    justify-content: flex-end;
    max-width: 850rem;

    > div {
        flex: 1;
        padding: 20rem;
        border-radius: 20rem;
        transition: all ease-in-out 200ms;
        max-width: calc(50% - 30rem);
        min-width: calc(50% - 30rem);
        cursor: pointer;

        .icon {
            color: #475569;
            background: #1e5bfa;
            box-shadow: 0 0 0 30rem #f1f5f9 inset;
            border-radius: 90rem;
            width: 45rem;
            height: 45rem;
            min-width: 45rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all linear 100ms;
        }

        .text-stack {
            padding: 10rem;

            .title {
                color: #313746;
                font-size: 20rem;
                font-family: Gilroy-Bold, sans-serif;
            }

            .description {
                font-family: Inter, sans-serif;
                font-weight: 400;
                color: #475569;
                line-height: 161.5%;
                font-size: 14rem;
            }
        }

        ${media(">=tablet")} {
            &:hover {
                background: ${coolGrey[50]};
            }

            &.selected {
                box-shadow: 0 25rem 50rem rgba(51, 65, 85, 0.1);
                transform: scale(1.05);
                background: #fff;

                .icon {
                    color: #ffffff;
                    box-shadow: 0 0 0 0 #f1f5f9 inset;
                }
            }
        }
    }

    ${media("<tablet")} {
        flex-flow: column;
        align-items: center;

        > .feature {
            max-width: 500rem;
            justify-content: center;
        }

        > .feature > div,
        .text-stack {
            flex-flow: column !important;
            align-items: center !important;
            justify-content: center !important;
            text-align: center;

            .description {
                font-size: 18rem;
                line-height: 200%;
            }
        }
    }
`

interface FeaturesListProps {
    features: Array<Feature>
    onChange: (feature: Feature) => void
    selectedFeature: string
}

export const FeaturesList = ({
    features,
    selectedFeature,
    onChange,
}: FeaturesListProps) => {
    return (
        <FeaturesListContainer>
            {features.map((f) => {
                return (
                    <div
                        key={f.title}
                        className={classNames("feature", {
                            selected: f.title === selectedFeature,
                        })}
                        onClick={() => onChange(f)}
                    >
                        <Flex gap={10}>
                            <div className={"icon"}>{f.icon}</div>
                            <Flex
                                direction={"column"}
                                gap={10}
                                className={"text-stack"}
                            >
                                <div className={"title"}>{f.title}</div>
                                <div className={"description"}>
                                    {f.description}
                                </div>
                            </Flex>
                        </Flex>
                    </div>
                )
            })}
        </FeaturesListContainer>
    )
}
