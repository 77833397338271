import React from "react"
import styled from "styled-components"
import { Flex } from "../../shared/Flex/Flex"
import { Logo } from "../../shared/Logo/Logo"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import { StaticImage } from "gatsby-plugin-image"
import media from "css-in-js-media"

const HeroHost = styled.div`
    flex: 1;
    max-width: ${({ theme }) => theme.maxWidth}rem;
    padding: ${({ theme }) => theme.spacing * 10}rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 40rem;

    .info-stack {
        .ShoutLogo-root {
            width: 124rem;
        }

        h1 {
            font-size: 64rem;
            font-family: Gilroy-Bold, sans-serif;
            color: #313746;
            margin: 0;
        }

        p {
            font-size: 20rem;
            color: #313746;
            width: 450rem;
            line-height: 158%;
            margin: 0;
        }

        .runs-on {
            margin-top: 13rem;

            .label {
                font-size: 10rem;
                font-family: Gilroy-Bold, sans-serif;
                line-height: 0;
                color: #6b7280;
            }
        }
    }

    .image {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .hero-desktop {
            max-width: 800rem;
        }

        .hero-mobile {
            display: none;
        }

        ${media("<tablet")} {
            .hero-mobile {
                //display: block;
            }

            .hero-desktop {
                display: none;
            }
        }
    }

    ${media("<largeDesktop")} {
        flex-direction: column-reverse;

        .info-stack {
            justify-content: center !important;
            align-items: center !important;
            text-align: center;

            .ShoutLogo-root {
                display: none;
            }

            p {
                max-width: fit-content;
                width: auto;
            }
        }
    }
`

export const Hero = () => {
    return (
        <HeroHost>
            <Flex direction={"column"} gap={25} className={"info-stack"}>
                <Logo />
                <h1 data-target={"home-hero-h1"}>
                    The new
                    <br /> business phone
                </h1>
                <p data-target={"home-hero-p"}>
                    No installs required; your team can make and receive calls
                    in minutes from their own devices
                </p>
                <a href={"//app.23shout.com"}>
                    <FlatButton
                        variant={"flat"}
                        color={"primary"}
                        size={"larger"}
                        data-target={"home-hero-button"}
                    >
                        Try it for free
                    </FlatButton>
                </a>
                <Flex className={"runs-on"} alignItems={"center"} gap={10}>
                    <div className={"label"}>RUNS ON</div>
                    <StaticImage
                        height={24}
                        src={"../../images/home/platforms.png"}
                        alt={"Mac, Windows, Chrome, Android, and iOS logos"}
                        placeholder={"none"}
                        objectFit={"contain"}
                        objectPosition={"center"}
                    />
                </Flex>
            </Flex>
            <div className={"image"}>
                <StaticImage
                    src={"../../images/home/hero-desktop.png"}
                    class={"hero-desktop"}
                    alt={"The application UI elements swirling in a circle"}
                    aspectRatio={0}
                    width={800}
                    placeholder={"none"}
                    quality={100}
                />
                <StaticImage
                    src={"../../images/home/hero-mobile.png"}
                    class={"hero-mobile"}
                    alt={"The application UI elements swirling in a circle"}
                    aspectRatio={0}
                    width={350}
                    layout={"constrained"}
                    placeholder={"none"}
                    quality={100}
                />
            </div>
        </HeroHost>
    )
}
