import React from "react"
import styled from "styled-components"
import { Flex } from "../../../shared/Flex/Flex"
import { StaticImage } from "gatsby-plugin-image"
import { TextButton } from "../../../shared/Ui/TextButton/TextButton"
import { Link } from "gatsby"
import media from "css-in-js-media"

const Host = styled.div`
    display: flex;
    background: linear-gradient(-160deg, #2563eb, rgb(37, 99, 235, 0)) #3b82f6;
    border-radius: 10rem 10rem 0 0;

    .left {
        flex: 1 20%;
        color: #fff;
        padding: 70rem 80rem;
        border-radius: 10rem 10rem 0 0;
        position: relative;
        background: linear-gradient(165deg, transparent 70%, #3b82f6 10%);

        .left-flex {
            > div {
                :first-of-type {
                    flex: 1 33%;
                }

                flex: 1;
            }
        }

        .logo {
            position: relative;
            left: -6rem;
        }

        .big-quote {
            font-family: Gilroy-Bold, sans-serif;
            font-size: 32rem;
            line-height: 139%;
            margin-bottom: 20rem;
            margin-top: 10rem;
            z-index: 100;
        }

        .name,
        .title {
            font-family: Gilroy-SemiBold, sans-serif;
            font-weight: 500;
        }

        .name {
            font-size: 20rem;
            margin-bottom: 5rem;
        }

        .title {
            font-size: 15rem;
        }

        .portrait-photo {
            position: absolute !important;
            bottom: 0;
            right: 0;
        }
    }

    .right {
        background: #fff;
        padding: 45rem 70rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 20rem;
        max-width: 500rem;
        border-radius: 0 10rem 0 0;

        .title {
            color: #313746;
            font-family: Gilroy-SemiBold, sans-serif;
            line-height: 154%;
            font-size: 24rem;
        }

        .body {
            color: #6b7280;
            font-family: Inter, sans-serif;
            font-weight: 400;
            line-height: 154.4%;
            font-size: 16rem;
            margin-bottom: 10rem;
        }
    }

    ${media("<desktop")} {
        flex-direction: column !important;

        .left-flex {
            > div:last-of-type {
                display: none;
            }
        }

        .right {
            max-width: unset;
            border-radius: 0;
            text-align: center;
            justify-content: center !important;
            align-items: center !important;
        }
    }
`

interface TestimonialUpperProps {
    logoImage: string
    companyName: string
    primaryQuote: string
    personName: string
    personTitle: string
    articleTitle: string
    articleText: string
    linkTo: string
}

export const TestimonialUpper = ({
    logoImage,
    companyName,
    primaryQuote,
    personName,
    personTitle,
    articleTitle,
    articleText,
    linkTo,
}: TestimonialUpperProps) => {
    return (
        <Host>
            <div className={"left"}>
                <Flex className={"left-flex"} alignItems={"stretch"}>
                    <Flex direction={"column"} justifyContent={"center"}>
                        <StaticImage
                            src={
                                "../../../images/home/logos/explorate-white.png"
                            }
                            width={200}
                            alt={`${companyName}'s logo`}
                            placeholder={"none"}
                            className={"logo"}
                            quality={100}
                        />
                        <div className={"big-quote"}>{primaryQuote}</div>
                        <div className={"name"}>{personName}</div>
                        <div className={"title"}>{personTitle}</div>
                    </Flex>
                    <div>
                        <StaticImage
                            src={
                                "../../../images/home/testimonials/profile-alex-ewart.png"
                            }
                            quality={100}
                            alt={"Alex Ewart's portrait photo"}
                            placeholder={"none"}
                            width={310}
                            className={"portrait-photo"}
                        />
                    </div>
                </Flex>
            </div>
            <div className={"right"}>
                <div className={"title"}>{articleTitle}</div>
                <div className={"body"}>{articleText}</div>
                <Link to={linkTo}>
                    <TextButton color={"primary"} size={"larger"}>
                        {companyName}'s story
                    </TextButton>
                </Link>
            </div>
        </Host>
    )
}
