import React, { ReactNode } from "react"
import styled from "styled-components"
import { Chip } from "../../../shared/Chip/Chip"
import { Constrained } from "../../../shared/Constrained/Constrained"
import { Depad } from "../../../shared/Depad/Depad"
import { StaticImage } from "gatsby-plugin-image"
import { IStaticImageProps } from "gatsby-plugin-image/dist/src/components/static-image.server"
import media from "css-in-js-media"

const Host = styled.div`
    display: flex;
    flex-direction: column;
    gap: 80rem;
    text-align: center;
    width: 100%;
    background: linear-gradient(180deg, #fff, #f8fafc);
    padding-bottom: 100rem;

    .circle {
        background: #e2e8f0;
        border-radius: 9000rem;
        width: 70rem;
        height: 5rem;
        margin: 80rem auto;
    }

    .header {
        max-width: 500rem;
        margin: 0 auto 70rem;

        h2 {
            font-size: 40rem;
            color: #1e293b;
            font-family: Gilroy-SemiBold, sans-serif;
            line-height: 160%;
            margin: 0 auto;
        }

        h3 {
            font-size: 24rem;
            color: #334155;
            font-family: Inter, sans-serif;
            font-weight: 500;
        }
    }

    .body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 40rem;

        > .integration {
            display: flex;
            justify-content: center;
            width: 400rem;

            .inner {
                display: flex;
                flex-direction: column;
                gap: 10rem;
                max-width: 300rem;

                .image {
                    display: flex;
                    justify-content: center;
                    width: fit-content;
                    overflow: hidden;
                    border-radius: 8rem;
                    margin: 0 auto 10rem;
                }

                .name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10rem;
                    color: #1e293b;
                    font-family: Inter, sans-serif;
                    font-weight: 600;
                    font-size: 18rem;
                }

                .description {
                    color: #334155;
                    font-size: 14rem;
                    line-height: 160%;
                }
            }
        }
    }

    ${media("<desktop")} {
        padding: 0 20rem;

        .header {
            h2 {
                font-size: 30rem;
            }
        }
    }
`

const ImageCommonProps: Partial<IStaticImageProps> = {
    layout: "fixed",
    objectFit: "contain",
    width: 80,
    height: 80,
    placeholder: "tracedSVG",
    objectPosition: "center",
    transformOptions: {
        cropFocus: "attention",
    },
}

const INTEGRATIONS: Array<{
    name: ReactNode
    image: ReactNode
    description: ReactNode
}> = [
    {
        name: "Zapier",
        image: (
            <StaticImage
                src={"./logo-zapier.png"}
                alt={"The Zapier logo"}
                {...ImageCommonProps}
            />
        ),
        description:
            "Interface with 5,000+ integrations and build highly automated workflows with 23shout.",
    },
    {
        name: "Hubspot",
        image: (
            <StaticImage
                src={"./logo-hubspot.png"}
                alt={"The Hubspot logo"}
                {...ImageCommonProps}
            />
        ),
        description:
            "Sync your contacts, calls, and data bi-directionally keeping your data up to date and clean.",
    },
    {
        name: "Pipedrive",
        image: (
            <StaticImage
                src={"./logo-pipedrive.png"}
                alt={"The Pipedrive logo"}
                {...ImageCommonProps}
            />
        ),
        description:
            "Close more faster by interfacing your teams data directly to your phone system.",
    },
    {
        name: "Google Meet",
        image: (
            <StaticImage
                src={"./logo-google-meet.png"}
                alt={"The Google Meet logo"}
                {...ImageCommonProps}
            />
        ),
        description:
            "Schedule and track Meets as part of your workflow and automate reminders around them.",
    },
    {
        name: (
            <>
                Salesforce <Chip body={"Coming soon"} variant={"info"} />
            </>
        ),
        image: (
            <StaticImage
                src={"./logo-salesforce.png"}
                alt={"The Salesforce logo"}
                {...ImageCommonProps}
            />
        ),
        description:
            "Extend the power of Salesforce with the comms of 23shout. Link records to contacts and sync activity back.",
    },
    {
        name: (
            <>
                Zoom <Chip body={"Coming soon"} variant={"info"} />
            </>
        ),
        image: (
            <StaticImage
                src={"./logo-zoom.png"}
                alt={"The Zoom logo"}
                {...ImageCommonProps}
            />
        ),
        description:
            "Schedule your Zoom calls as part of your workflow, and automate reminders around them.",
    },

    // {
    //     name: (
    //         <>
    //             Gong <Chip body={"Coming soon"} variant={"info"} />
    //         </>
    //     ),
    //     image: (
    //         <StaticImage
    //             src={"./logo-gong.png"}
    //             alt={"The Gong logo"}
    //             {...ImageCommonProps}
    //         />
    //     ),
    //     description:
    //         "Interface with 5,000+ integrations and build highly automated workflows with 23shout.",
    // },
]

export const Extensions = () => {
    return (
        <Depad>
            <Host>
                <Constrained>
                    <div className={"circle"} />
                    <section className={"header"}>
                        <h2>Connect to the tools you already know and use</h2>
                        {/*<h3>Leverage our integrations to move quickly</h3>*/}
                    </section>
                    <section className={"body"}>
                        {INTEGRATIONS.map((integration, i) => {
                            return (
                                <div className={"integration"} key={`int-${i}`}>
                                    <div className={"inner"}>
                                        <div className={"image"}>
                                            {integration.image}
                                        </div>
                                        <div className={"name"}>
                                            {integration.name}
                                        </div>
                                        <div className={"description"}>
                                            {integration.description}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </section>
                </Constrained>
            </Host>
        </Depad>
    )
}
